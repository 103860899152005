import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import { Image } from "lib/imgproxy";

/**
 * Props for `InfographicsSlice`.
 */
export type InfographicsSliceProps =
    SliceComponentProps<Content.InfographicsSliceSlice>;

/**
 * Component for "InfographicsSlice" Slices.
 */
const InfographicsSlice = ({ slice }: InfographicsSliceProps): JSX.Element => (
    <section
        data-slice-type={slice.slice_type}
        data-slice-variation={slice.variation}
        className="flex flex-col items-center gap-10"
    >
        <div className="typography-overline-lg text-blue-grey">
            {slice.primary.title}
        </div>
        <div className="flex flex-col items-center gap-8">
            <div className="grid grid-cols-2 gap-[57px] lg:grid-cols-4 lg:gap-[117px]">
                {slice.primary.infographics.map((i) => (
                    <div
                        key={i.description}
                        className="flex flex-col items-center gap-5"
                    >
                        <Image
                            alt={i.icon.alt ?? ""}
                            src={i.icon.url ?? ""}
                            objectFit="contain"
                            unoptimized
                            height="80px"
                            width="80px"
                        />
                        <div className="flex flex-col items-center">
                            <span className="typography-h1 lg:typography-display-sm font-light text-blue-grey-900">
                                {i.numbers}
                            </span>
                            <span className="typography-main text-blue-grey">
                                {i.description}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
            <div className="typography-sub italic text-blue-grey-300">
                {slice.primary.timestamp}
            </div>
        </div>
    </section>
);

export default InfographicsSlice;
